import styled from "styled-components";

export const Container = styled.div`
  margin-top: -60px;

  @media (max-width: 500px) {
    margin-top: 32px;
    padding: 0 20px;
  }
`;

export const Title = styled.h1`
  color: #eee;
  font-family: "Play";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 1.44px;
  margin-bottom: 32px;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 24px;
    line-height: 36px;

    span {
      display: block;
    }
  }
`;

export const Logo = styled.img`
  width: 240px;
  display: block;
  margin: 0 auto 80px;

  @media (max-width: 500px) {
    width: 72px;
  }
`;

export const SubTitle = styled.h2`
  color: #50d05d;
  font-family: "Play";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 1.44px;
  margin-bottom: 50px;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 24px;
    margin-bottom: 24px;
  }
`;

export const Text = styled.p`
  color: #eee;
  font-family: "Lato";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 72px;
  text-align: center;

  span {
    color: #50d05d;
    font-weight: 700;
  }

  @media (max-width: 500px) {
    font-size: 16px;
    margin-bottom: 24px;
  }
`;

export const WraperCardsFlex = styled.div`
  display: flex;
  justify-content: center;
`;

export const WraperCardsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: fit-content;
  margin: 0 auto;
  gap: 16px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Card = styled.div``;

export const Localization = styled.h4`
  color: #50d05d;
  font-family: "Lato";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.72px;
  display: flex;
  text-align: left;
  gap: 0 12px;
  align-items: center;
  margin-bottom: 48px;

  @media (max-width: 500px) {
    font-size: 20px;

    gap: 16px 0;
    flex-direction: column;
    margin-bottom: 16px;
  }
`;

export const Contact = styled.h5`
  color: #eee;
  text-align: center;

  font-family: "Lato";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 70px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 12px;

  @media (max-width: 500px) {
    font-size: 20px;
    margin-bottom: 40px;
  }
`;
