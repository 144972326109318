import styled, { css } from "styled-components";

export const Container = styled.main`
  ${({ theme: { space } }) => css`
    /* max-width: ${space.large}; */
    width: 100%;
    margin: 0 auto;
    padding: 80px 0 0;

    background: hsla(129, 47%, 12%, 1);

    background: linear-gradient(
      315deg,
      hsla(129, 47%, 12%, 1) 0%,
      hsla(0, 0%, 7%, 1) 32%,
      hsla(0, 0%, 7%, 1) 100%,
      hsla(0, 0%, 7%, 1) 100%
    );

    background: -moz-linear-gradient(
      315deg,
      hsla(129, 47%, 12%, 1) 0%,
      hsla(0, 0%, 7%, 1) 32%,
      hsla(0, 0%, 7%, 1) 100%,
      hsla(0, 0%, 7%, 1) 100%
    );

    background: -webkit-linear-gradient(
      315deg,
      hsla(129, 47%, 12%, 1) 0%,
      hsla(0, 0%, 7%, 1) 32%,
      hsla(0, 0%, 7%, 1) 100%,
      hsla(0, 0%, 7%, 1) 100%
    );

    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#102C14", endColorstr="#111111", GradientType=1 );

    &[data-theme-light="true"] {
      background: transparent;
    }

    @media (max-width: 500px) {
      padding: 0;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme: { space } }) => css`
    max-width: ${space.medio};
    width: 100%;
    margin: 0 auto;
    padding: 140px 0;

    @media (max-width: 500px) {
      padding: 32px 0;
    }
  `}
`;
