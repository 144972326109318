import { Link } from "react-router-dom";
import * as S from "./styles";
import { useCookieBannerPage } from "./useCookieBannerPage";

export const CookieBannerPage: React.FC = () => {
  const { handleAccept, isVisible } = useCookieBannerPage();

  return (
    <S.Container visible={isVisible}>
      <p>
        Utilizamos cookies para oferecer melhor experiência, melhorar o
        desempenho, analisar como você interage em nosso site e personalizar
        conteúdo.
      </p>
      <p>
        Nós fizemos importantes modificações nos termos de nossa Política de
        Privacidade, caso queira saber mais{" "}
        <Link to={"/termos-de-uso"}>clique aqui</Link>.
      </p>
      <S.WrapperButtons>
        <Link to={"/politica-de-privacidade"}>Política de Privacidade</Link>
        <button onClick={handleAccept}>OK</button>
      </S.WrapperButtons>
    </S.Container>
  );
};

export default CookieBannerPage;
