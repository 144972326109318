import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 80px 20px 0;
  max-width: 1160px;
  @media (max-width: 500px) {
    padding: 20px 20px;
  }
`;

export const Content = styled.div`
  h1 {
    text-align: center;
    color: #111;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 48px;
  }

  > div {
    > * {
      margin-top: 16px;
    }

    p {
      color: #2d2d2d;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }

    p.title-section {
      font-weight: 700;
    }

    > ul {
      padding-left: 16px;

      > li {
        list-style: disc;
      }

      > li + li {
        margin-top: 16px;
      }
    }
  }

  @media (min-width: 640px) {
    h1 {
      font-size: 32px;
    }
  }
`;
