import React from "react";
import ReactDOM from "react-dom/client";

import { GlobalStyles } from "./Global/GlobalStyles";
import { ThemeProvider } from "styled-components";
import { Theme } from "./Global/Theme";
import { Home } from "./components/Pages/Home";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ContextProvider } from "./context/Context";

import "react-toastify/dist/ReactToastify.css";
import { Offline } from "./components/Pages/Offline";

import "react-toastify/dist/ReactToastify.css";
import { TermsOfUse } from "./components/Pages/TermsOfUse";
import { PrivacyPolicy } from "./components/Pages/PrivacyPolicy";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const RedirectToLoginCadastro: React.FC = () => {
  window.location.replace(
    "https://mapa.logvistorias.com.br/agendamento/login-cadastro"
  );
  return null;
};
const RedirectToAgendamento: React.FC = () => {
  window.location.replace("https://mapa.logvistorias.com.br/agendamento");
  return null;
};

root.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <ToastContainer autoClose={2000} />
      <GlobalStyles />
      <ContextProvider>
        <BrowserRouter>
          <Routes>
            {/* <Route index element={<Navigate to={"/offline"} />} /> */}
            <Route index element={<Home />} />
            <Route path="termos-de-uso" element={<TermsOfUse />} />
            <Route path="politica-de-privacidade" element={<PrivacyPolicy />} />
            <Route path="offline" element={<Offline />} />
            <Route
              path="login-cadastro"
              element={<RedirectToLoginCadastro />}
            />
            <Route
              path="agendamento"
              element={<RedirectToAgendamento />}
            ></Route>
          </Routes>
        </BrowserRouter>
      </ContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);
