import React, { ComponentProps, useEffect, useState } from "react";
import * as S from "./styles";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { IoClose } from "react-icons/io5";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { cleanStorage } from "../../../utils/cleanStorage";
import { RolesEnum } from "../../../enums/roles";

export interface IMainMenuProps extends ComponentProps<"div"> {
  logo?: string;
  theme?: "dark" | "light";
}

export const MainMenu = (props: IMainMenuProps) => {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery({ maxWidth: "500px" });
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [token] = useSessionStorage("@token");

  const [cliente, setCliente] = useSessionStorage("cliente");

  const isCliente = !!(
    cliente?.role?.includes(RolesEnum.ROLE_CLIENTE) && token
  );

  const isAdmGerente = cliente?.role?.some(
    (regra) =>
      regra === RolesEnum.ROLE_ADMIN || regra === RolesEnum.ROLE_GERENTE
  );

  const isOffline = pathname.includes("offline");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function handleLoginLogout() {
    if (token) {
      sessionStorage.removeItem("@token");
      cleanStorage();
      window.open("/", "_self");
      return;
    }

    window.open("/login-cadastro", "_self");
  }

  return (
    <S.Header data-hidden={isOffline}>
      <S.WrapperMainMenu>
        <Link to={"/"}>
          <S.Logo
            src={props.logo ? props.logo : "/assets/imgs/logo.png"}
            alt="logo log vistorias"
          />
        </Link>
        <S.MainMenu {...props}>
          <li>
            <NavHashLink
              smooth={true}
              to={pathname !== "/" ? "/#sobre" : "#sobre"}
            >
              Sobre Nós
            </NavHashLink>
          </li>
          <li>
            <NavHashLink
              smooth={true}
              to={pathname !== "/" ? "/#servicos" : "#servicos"}
            >
              Serviços
            </NavHashLink>
          </li>
          <li>
            <NavHashLink
              smooth={true}
              to={pathname !== "/" ? "/#localizacao" : "#localizacao"}
            >
              Localização
            </NavHashLink>
          </li>
          <li>
            <NavHashLink
              smooth={true}
              to={pathname !== "/" ? "/#contatos" : "#contatos"}
            >
              Contatos
            </NavHashLink>
          </li>

          {isCliente && (
            <li className="agendamento">
              <Link
                data-variant-default={pathname !== "/"}
                to={"/meus-agendamentos"}
              >
                Meus Agendamentos
              </Link>
            </li>
          )}
          {isAdmGerente && (
            <li className="agendamento">
              <Link
                data-variant-default={pathname !== "/"}
                to={"/meus-agendamentos"}
              >
                Agendamentos
              </Link>
            </li>
          )}
        </S.MainMenu>

        <S.WrapperButtons>
          <Link to={"/login-cadastro"}>
            <S.ButtonLogin onClick={handleLoginLogout}>
              {" "}
              {token ? "Logout" : "Login"}
            </S.ButtonLogin>
          </Link>
        </S.WrapperButtons>
        <S.MenuMobileIcon
          onClick={() => setMenuIsOpen(true)}
          alt="icone menu hamburguer"
          src="/assets/imgs/menu-mobile.svg"
        />
      </S.WrapperMainMenu>
      {menuIsOpen && (
        <S.MenuMobile>
          <S.MenuHeaderWrapper>
            <S.LogoMobile
              alt="icone logo log"
              src="/assets/imgs/log-logo.svg"
            />
            <IoClose
              size={32}
              color="#fff"
              onClick={() => setMenuIsOpen(false)}
            />
          </S.MenuHeaderWrapper>
          <S.MenuMobileWrapper>
            <S.MenuMobileItem>
              <Link
                to={"/login-cadastro"}
                onClick={() => {
                  handleLoginLogout();
                  setMenuIsOpen(false);
                }}
              >
                {token ? "Logout" : "Login"}
              </Link>
            </S.MenuMobileItem>
            <S.MenuMobileItem>
              <HashLink
                onClick={() => setMenuIsOpen(false)}
                data-variant-default={pathname !== "/"}
                smooth
                to={pathname !== "/" ? "/#sobre" : "#sobre"}
              >
                Quem somos
              </HashLink>
            </S.MenuMobileItem>
            <S.MenuMobileItem>
              <HashLink
                onClick={() => setMenuIsOpen(false)}
                data-variant-default={pathname !== "/"}
                smooth
                to={pathname !== "/" ? "/#servicos" : "#servicos"}
              >
                Serviços
              </HashLink>
            </S.MenuMobileItem>
            <S.MenuMobileItem>
              <HashLink
                onClick={() => setMenuIsOpen(false)}
                data-variant-default={pathname !== "/"}
                smooth
                to={pathname !== "/" ? "/#localizacao" : "#localizacao"}
              >
                Localização
              </HashLink>
            </S.MenuMobileItem>
            <S.MenuMobileItem>
              <HashLink
                onClick={() => setMenuIsOpen(false)}
                data-variant-default={pathname !== "/"}
                smooth
                to={pathname !== "/" ? "/#contato" : "#contato"}
              >
                Contatos
              </HashLink>
            </S.MenuMobileItem>
            <S.MenuMobileItem>
              <HashLink
                onClick={() => setMenuIsOpen(false)}
                data-variant-default={pathname !== "/"}
                smooth
                to={pathname !== "/" ? "/#sobre" : "#sobre"}
              >
                Quem somos
              </HashLink>
            </S.MenuMobileItem>

            {isCliente && (
              <S.MenuMobileItem>
                <Link
                  onClick={() => setMenuIsOpen(false)}
                  to={"/meus-agendamentos"}
                >
                  Meus Agendamentos
                </Link>
              </S.MenuMobileItem>
            )}
            {isAdmGerente && (
              <S.MenuMobileItem>
                <Link
                  onClick={() => setMenuIsOpen(false)}
                  to={"/meus-agendamentos"}
                >
                  Agendamentos
                </Link>
              </S.MenuMobileItem>
            )}
          </S.MenuMobileWrapper>
        </S.MenuMobile>
      )}
    </S.Header>
  );
};
