import React from "react";
import * as S from "./styles";
import { useFooter } from "./useFooter";

export const Footer = () => {
  const { navigate } = useFooter();

  return (
    <S.Container>
      <S.FirstSection>
        <S.FirstSectionContent>
          <S.Logo src="/assets/imgs/log-logo-green.svg" alt="logo da empresa" />
          <S.Wrapper>
            <div>
              <h2>Fale conosco</h2>
              <p>
                <img src="/assets/imgs/email.svg" alt="icone de carta" />
                ouvidoria@logvistorias.com
              </p>
            </div>

            <div>
              <h2>Redes Sociais</h2>
              <ul>
                <li>
                  <a href="#">
                    <img src="/assets/imgs/facebook.svg" alt="icone facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/logvistorias/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/imgs/instagram.svg"
                      alt="icone instagram"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="/assets/imgs/youtube.svg" alt="icone youtube" />
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h2>Confira os itens verificados</h2>
              <S.LinkDownDoc href="/assets/docs/checklist-log.pdf" download>
                <img
                  src="/assets/imgs/icon-checklist.svg"
                  alt="icone checklist"
                />
                <span>Baixar Checklist</span>
              </S.LinkDownDoc>
            </div>
          </S.Wrapper>

          <S.WrapperLocalizations>
            <h2>Localização</h2>
            <p>São Luís / MA</p>
            <p>Pres. Dutra / MA</p>
            <p>Timon / MA</p>
            <p>Carolina/ MA</p>
            <p>Açailandia/ MA</p>
          </S.WrapperLocalizations>
        </S.FirstSectionContent>
      </S.FirstSection>
      <S.SecondSection>
        <p>Direitos Autorais © 2023 Log Vistorias </p>
        <div className="wrapperBuutons">
          <button onClick={() => navigate("/termos-de-uso")}>
            Termos de Uso
          </button>
          <span>|</span>
          <button onClick={() => navigate("/politica-de-privacidade")}>
            Política de Privacidade
          </button>
        </div>
      </S.SecondSection>
    </S.Container>
  );
};
