import styled, { css } from "styled-components";

export const Container = styled.footer`
  ${({ theme: { space } }) => css`
    /* max-width: ${space.large}; */
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #50d05d;
  `}
`;

export const FirstSection = styled.div`
  width: 100%;
  background-color: #111;
  padding: 120px 0;

  @media (max-width: 500px) {
    padding: 40px 0;
  }
`;

export const Logo = styled.img`
  width: 241px;
  display: block;

  @media (max-width: 500px) {
    width: 138px;
  }
`;

export const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 32px 0;

  ul {
    display: flex;
    gap: 0 20px;
    align-items: center;
  }

  h2 {
    color: #50d05d;
    font-family: "Play";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 22px;
    line-height: 32px;
    letter-spacing: 0.72px;
  }

  p {
    color: #f7f7f7;
    font-family: "Lato";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.54px;
    display: flex;
    gap: 0 6px;
    align-items: center;
    margin-bottom: 22px;

    /* &:has(> img) {
      margin-bottom: 40px;
    } */
  }

  @media (max-width: 500px) {
    ul {
      justify-content: center;
    }

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
    }
  }
`;

export const WrapperLocalizations = styled.div`
  width: fit-content;

  ul {
    display: flex;
    gap: 0 20px;
    align-items: center;
  }

  h2 {
    color: #50d05d;
    font-family: "Play";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 22px;
    line-height: 32px;
    letter-spacing: 0.72px;
  }

  p {
    color: #f7f7f7;
    font-family: "Lato";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.54px;
    display: flex;
    gap: 0 6px;
    align-items: center;
    margin-bottom: 22px;

    &:has(> img) {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 500px) {
    ul {
      justify-content: center;
    }

    h2 {
      font-size: 24px;
    }

    p {
      text-align: center;
      font-size: 16px;
      width: 100%;
      display: block;
    }
  }
`;

export const FirstSectionContent = styled.div`
  ${({ theme: { space } }) => css`
    width: ${space.medio};
    margin: 0 auto;
    width: 100%;

    display: flex;
    justify-content: center;
    gap: 0 120px;
    align-items: center;

    @media (max-width: 500px) {
      flex-direction: column;
      align-items: center;
      gap: 32px 0;

      text-align: center;
    }
  `}
`;

export const SecondSection = styled.div`
  height: 128px;
  display: flex;
  gap: 0 120px;

  justify-content: center;
  align-items: center;
  background-color: #50d05d;

  div.wrapperBuutons {
    display: flex;
    gap: 0 6px;
  }

  button {
    all: unset;
    cursor: pointer;
  }

  p,
  button {
    color: #111;
    font-family: "Lato";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.48px;
  }

  @media (max-width: 500px) {
    height: 108px;
    gap: 16px 0;
    flex-direction: column;

    p,
    button {
      line-height: 16px;
      text-align: center;
      display: block;
    }
  }
`;

export const LinkDownDoc = styled.a`
  display: flex;
  gap: 0 8px;
  align-items: center;

  > span {
    color: #f7f7f7;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 100% */
    text-decoration-line: underline;
  }

  @media (max-width: 500px) {
    justify-content: center;
    margin-bottom: 32px;
  }
`;
