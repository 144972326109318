import React, { ComponentProps, useEffect } from "react";
import * as S from "./styles";
import { MainMenu } from "../../Molecules/MainMenu";

import { Footer } from "../../Molecules/Footer";

interface ILayoutTemplateProps extends ComponentProps<"div"> {
  children: React.ReactNode;
  theme?: "dark" | "light";
  logo?: string;
}

export const LayoutTemplate = ({
  children,
  theme,
  logo,
  ...rest
}: ILayoutTemplateProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Container data-theme-light={theme === "light"}>
      <MainMenu theme={theme} logo={logo} />
      <S.Content>{children}</S.Content>
      <Footer />
    </S.Container>
  );
};
