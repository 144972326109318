import styled, { css } from "styled-components";

export const Button = styled.button<{ $disabled?: boolean }>`
  ${({ $disabled }) => css`
    cursor: ${$disabled ? "not-allowed" : "pointer"};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    min-width: 220px;

    border-radius: 10px;
    background: #50d05d;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.25);

    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.48px;

    &[data-variant-wrap] {
      min-width: 180px;
      max-width: 190px;
      padding: 15px 15px;
      height: 70px;
    }

    &[data-variant-login="true"] {
      border: 1px solid #50d05d;
      background: rgba(0, 0, 0, 0.22);
      padding: 15px 75px;

      text-align: center;
      font-family: "Play";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0.72px;
    }

    &[data-variant-dark="true"] {
      background: #001f03;
      box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.25);
    }

    &[data-variant-ghost="true"] {
      background: transparent;
      color: #717171;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.6px;
      text-transform: none;
      min-width: fit-content;
      box-shadow: none;
    }

    &:disabled {
      cursor: not-allowed;
    }

    @media (max-width: 500px) {
      color: #fff;
      text-align: center;
      font-family: Play;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.42px;
      padding: 14px 16px;
      width: fit-content;
      height: fit-content;
      max-width: fit-content;
      min-width: fit-content;
    }
  `}
`;
