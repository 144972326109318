import styled, { css } from "styled-components";
import { IMainMenuProps } from ".";

export const Header = styled.header`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

  &[data-hidden="true"] {
    display: none;
  }
`;

export const MainMenu = styled.ul<IMainMenuProps>`
  display: flex;
  align-items: center;

  li {
    padding: 0 20px;

    a {
      color: ${(props) => (props.theme === "light" ? "#000" : "#eee")};
      text-align: center;
      font-family: "Play";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.54px;
      transition: color 0.3s ease-out;
      white-space: nowrap;

      &:hover {
        color: #50d05d;
      }
    }
  }

  li + li {
    border-left: 1px solid #50d05d;
  }

  li + li.agendamento {
    border-left: none;
    a {
      color: #50d05d;
    }
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const Logo = styled.img`
  @media (max-width: 500px) {
    width: 64px;
    display: block;
  }
`;

export const MenuMobileIcon = styled.img`
  cursor: pointer;

  display: none;

  @media (max-width: 500px) {
    display: block;
  }
`;

export const WrapperMainMenu = styled.nav`
  ${({ theme: { space } }) => css`
    max-width: ${space.medio};
    width: 100%;
    margin: 0 auto;
    padding: 40px 20px 0;

    display: flex;
    gap: 0 70px;
    justify-content: space-between;
    align-items: center;
  `}

  @media (max-width: 500px) {
    gap: 0;
  }
`;

export const WrapperButtons = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;

export const ButtonLogin = styled.button`
  display: flex;
  width: 132px;
  height: 54px;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  background: #4bc657;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  color: #eee;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.48px;
  text-transform: uppercase;
`;

export const MenuMobile = styled.nav`
  position: fixed;
  background-color: #111;
  inset: 0;
  z-index: 2;
  padding: 40px 20px;

  @media (min-width: 501px) {
    display: none;
  }
`;

export const MenuHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
`;

export const LogoMobile = styled.img`
  width: 80px;
`;

export const MenuMobileWrapper = styled.ul``;

export const MenuMobileItem = styled.li`
  text-align: center;

  & + li {
    margin-top: 20px;
    border-top: 1px solid #fff;
    padding-top: 20px;
  }

  a {
    color: #fff;
    font-size: 20px;
    font-family: Lato;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.03em;
    text-align: center;
  }
`;
