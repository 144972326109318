import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #111;

  .priceTableRed {
    color: red;
    font-size: 24px;
    padding-left: 5px;
    position: relative;
    top: -20px;
  }

  @media (max-width: 500px) {
    .priceTableRed {
      font-size: 18px;
      top: -8px;
      padding-left: 3px;
    }
  }
`;

export const Main = styled.main`
  margin: 0 auto;
  padding: 80px 0;
  background-image: url("/assets/imgs/fundo-desktop.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center top;
  height: 940px;

  h1 {
    color: #50d05d;
    font-family: "Play";
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 2.16px;
    margin-bottom: 40px;
  }

  p {
    max-width: 730px;
    margin-bottom: 72px;
  }

  @media (max-width: 500px) {
    padding: 0;
    height: 700px;

    background-size: cover;
    background-position: center top;
    background-image: url("/assets/imgs/fundo-mobile.png");

    h1 {
      font-size: 24px;
      margin-bottom: 0;
    }
  }
`;

export const MainContent = styled.div`
  ${({ theme: { space } }) => css`
    width: 100%;
    max-width: ${space.medio};
    margin: 0 auto;
    padding: 120px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "title ." "text ." "button .";
    align-items: center;
    gap: 32px;

    > :nth-child(1) {
      grid-area: title;

      > h1 {
        color: #f8f8f8;
        font-family: Play;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 72px; /* 112.5% */
        letter-spacing: 1.92px;
        text-transform: uppercase;

        span {
          color: #50d05d;
          display: block;
        }
      }
    }

    > :nth-child(2) {
      grid-area: text;
    }

    > :nth-child(3) {
      grid-area: button;
    }

    @media (max-width: 500px) {
      padding: 40px 0;

      display: flex;
      flex-direction: column;
      gap: 24px 0;
      align-items: center;

      > :nth-child(1) {
        > h1 {
          font-size: 24px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0.72px;
          text-transform: uppercase;
          text-align: center;
        }
      }

      > :nth-child(2) {
        p {
          margin: 0;
          width: 300px;
          text-align: start;
        }
      }

      > :nth-child(3) {
        button {
          white-space: nowrap;
        }
      }
    }
  `}
`;

export const TextGreen = styled.span`
  color: #50d05d;
  font-weight: 700;
`;

export const Text = styled.p`
  color: #eee;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  letter-spacing: 0.6px;
  width: 375px;

  @media (max-width: 500px) {
    font-size: 16px;
    max-width: 302px;
  }
`;

export const AboutSection = styled.section`
  ${({ theme: { space } }) => css`
    max-width: ${space.large};
    position: relative;
    width: 100%;
    margin: 0 auto;
    background-color: #111;
    padding: 20px 0 120px;

    &::after {
      content: "";
      top: -80px;
      display: block;
      position: absolute;
      border-top: 6px solid #50d05d;
      width: 100%;
      height: 100px;
      background-color: #111;
      transform: skewY(-4deg);
      z-index: 0;
    }

    @media (max-width: 500px) {
      padding: 20px;

      &::after {
        left: 0;
        top: -18px;
      }
    }
  `}
`;

export const AboutContent = styled.div`
  ${({ theme: { space } }) => css`
    max-width: ${space.medio};
    width: 100%;
    margin: 0 auto;

    h2 {
      color: #f7f7f7;
      text-align: center;
      font-family: "Play";
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      position: relative;
      z-index: 1;

      letter-spacing: 1.2px;
      margin-bottom: 120px;

      span {
        color: #26be51;
      }
    }

    button {
      margin: 0 auto;
    }

    @media (max-width: 500px) {
      h2 {
        font-size: 24px;
        margin-bottom: 40px;
      }

      > a button {
        margin: 40px auto;
      }
    }
  `}
`;

export const Flex = styled.div<{
  $justifyContent?: string;
  $alignItems?: string;
  $gap?: string;
}>`
  ${({ $alignItems, $gap, $justifyContent }) => css`
    display: flex;
    justify-content: ${$justifyContent};
    align-items: ${$alignItems};
    gap: ${$gap};
    margin-bottom: 120px;

    @media (max-width: 500px) {
      flex-direction: column;
      gap: 40px 0;
      margin-bottom: 0;

      &#missao {
        display: none;
      }
    }
  `}
`;

export const WrapperValores = styled.div`
  display: flex;
  gap: 40px;
  height: 500px;
  margin-bottom: 60px;

  > :nth-child(2) {
    align-self: center;
    div {
      min-height: 288px;
    }
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const card = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    color: #f7f7f7;
    text-align: center;
    font-family: "Play";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 1.28px;
    display: flex;
    gap: 0 14px;
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;
  }

  p {
    color: #f7f7f7;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.6px;

    span {
      color: #50d05d;
      font-weight: 700;
    }
  }

  @media (max-width: 500px) {
    h3 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
    }
  }
`;

export const cardBorder = styled.div`
  border-radius: 88px;
  border: 1px solid #50d05d;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 32px;
  flex: 1;
  width: 360px;
  max-height: 288px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    li {
      list-style-image: url("/assets/imgs/circle.svg");
      width: fit-content;
      color: #f7f7f7;
      font-family: "Play";
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 32px;
      margin-bottom: 20px;
    }
  }
`;

export const ServicesSection = styled.section`
  ${({ theme: { space } }) => css`
    /* max-width: ${space.large}; */
    width: 100%;
    margin: 0 auto;
    background-color: #2cb853;
    padding: 100px 0;

    @media (max-width: 500px) {
      padding: 40px 20px;
    }
  `}
`;

export const ServicesContent = styled.div`
  ${({ theme: { space } }) => css`
    max-width: ${space.medio};
    width: 100%;
    margin: 0 auto;
  `}
`;

export const ServiceGroup1 = styled.div`
  h2 {
    color: #111;
    font-family: Play;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px; /* 116.667% */
    letter-spacing: 1.44px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  @media (max-width: 500px) {
    flex-direction: column;

    h2 {
      font-size: 28px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0.84px;
      text-align: center;
    }
  }
`;

export const WrapperContent = styled.div`
  background-image: url("/assets/imgs/carro1.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px 0;

  a#btn_checklist {
    color: #1c1c1c;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    display: flex;
    gap: 0 8px;
    align-items: center;
    cursor: pointer;
  }

  h4 {
    color: #eee;
    font-family: Play;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 100% */
    letter-spacing: 1.92px;
  }

  p {
    color: #111;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
    letter-spacing: 0.6px;
    max-width: 430px;

    span {
      font-weight: 700;
    }
  }

  @media (max-width: 500px) {
    height: 340px;
    background-image: url("/assets/imgs/carro1-mobile.png");
    background-size: auto;
    background-position: bottom;

    h4 {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 1.2px;
      text-align: center;
    }

    p {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.48px;
    }
  }
`;

export const RedItem = styled.span`
  color: #ed0000;
  font-size: 20px;
  position: relative;
`;

export const PriceText = styled.p`
  color: #f7f7f7;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 1.44px;
`;

export const TypeText = styled.p`
  color: #f7f7f7;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.72px;
`;

export const PriceTable = styled.div`
  border-radius: 10px;
  background: #111;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  max-width: 607px;
  width: 100%;
  max-height: 365px;
  height: 100%;

  display: flex;
  flex-direction: column;

  span.priceTableRedSecundary {
    color: red;
    font-size: 24px;
    position: relative;
    padding-left: 5px;
    top: 0;
  }

  @media (max-width: 500px) {
    span.priceTableRedSecundary {
      color: red;
      font-size: 18px;
      padding-left: 3px;
    }
  }
`;

export const PriceTableContent1 = styled.div`
  height: 172px;

  display: flex;
  align-items: center;

  h2 {
    color: #f7f7f7;
    text-align: center;
    font-family: Play;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0.96px;
    max-width: 400px;

    margin: 0 auto;

    span {
      display: block;
      color: #50d05d;
    }
  }

  @media (max-width: 500px) {
    height: 88px;

    h2 {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

export const PriceTableContent2 = styled.div`
  height: 72px;
  border-top: 2px solid #50d05d;
  border-bottom: 2px solid #50d05d;
  display: flex;

  > :nth-child(1) {
    width: 50%;
    border-right: 2px solid #50d05d;

    display: flex;
    align-items: center;

    position: relative;

    p {
      position: absolute;
      left: 50px;
    }
  }

  > :nth-child(2) {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 500px) {
    height: 48px;
  }
`;

export const PriceTableText = styled.p`
  color: #f7f7f7;
  font-family: Lato;
  font-size: 24px;
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.72px;

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const PriceTablePrice = styled.h3`
  color: #f7f7f7;
  font-family: Lato;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 1.44px;

  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 32px;
  }
`;

export const PriceTableContent3 = styled.div`
  height: 120px;
  display: flex;

  > :nth-child(1) {
    position: relative;
    width: 50%;
    border-right: 2px solid #50d05d;
    display: flex;
    align-items: center;

    p {
      position: absolute;
      left: 40px;
      top: 20px;
      max-width: 232px;
    }
  }

  > :nth-child(2) {
    width: 50%;
    position: relative;
    display: flex;
    align-items: start;
    justify-content: center;

    h3 {
      position: absolute;
      top: 28px;
    }
  }

  @media (max-width: 500px) {
    > :nth-child(1) {
      p {
        left: 60px;
        top: 20px;
        max-width: 80px;
      }
    }

    > :nth-child(2) {
      top: -14px;

      h3 {
        top: 55px;
      }
    }
  }
`;

export const TablePriceInloco = styled.table`
  display: block;
  width: 382px;
  height: 320px;
  border-radius: 10px;
  background: linear-gradient(180deg, #000 0%, #1e1e1e 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  > :nth-child(1) {
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      color: #fff;
      text-align: center;
      font-family: Play;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px;
      letter-spacing: 0.96px;
      max-width: 330px;

      span {
        color: #50d05d;
      }
    }
  }

  > :nth-child(2) {
    height: 154px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid #50d05d;
  }

  @media (max-width: 500px) {
    max-width: 236px;
    max-height: 181px;

    > :nth-child(1) {
      height: 60%;

      h2 {
        font-size: 20px;
        line-height: 24px;
      }
    }

    > :nth-child(2) {
      height: 40%;
    }
  }
`;

export const TextRightSide = styled.p`
  color: #111;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.6px;
  margin-bottom: 20px;

  span {
    font-weight: 700;
  }

  @media (max-width: 500px) {
    font-size: 16px;
    max-width: 260px;
    margin: 0 auto 24px;
  }
`;

export const Astesriscos = styled.div`
  display: flex;
  padding-top: 6px;
  align-items: start;
  gap: 0 4px;
`;

export const TdContent = styled.div`
  display: flex;
  gap: 0 6px;
  justify-content: center;

  @media (max-width: 500px) {
    img {
      width: 8px;
    }
  }
`;

export const SectionLocalizations = styled.section`
  ${({ theme: { space } }) => css`
    max-width: ${space.large};
    width: 100%;
    margin: 0 auto;
    background-color: #111;
    padding: 120px 0;

    @media (max-width: 500px) {
      padding: 40px 40px;
    }
  `}
`;

export const LocalizationsContent = styled.section`
  ${({ theme: { space } }) => css`
    max-width: ${space.medio};
    width: 100%;
    margin: 0 auto;

    > h2 {
      color: #50d05d;
      text-align: center;
      font-family: "Play";
      font-size: 72px;
      font-style: normal;
      font-weight: 400;
      line-height: 72px;
      margin-bottom: 32px;
      letter-spacing: 2.16px;
    }

    > p {
      color: #f7f7f7;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      margin: 0 auto;
      max-width: 884px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0.6px;

      span {
        color: #50d05d;
        font-weight: 700;
      }
    }

    @media (max-width: 500px) {
      h2 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
      > p {
        display: none;
      }
    }
  `}
`;

export const WrapperCards = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 72px;
  padding-top: 120px;

  @media (max-width: 500px) {
    padding-top: 0px;
    flex-direction: column;
    gap: 40px 0;
    margin-bottom: 40px;
  }
`;

export const CardMap = styled.div``;

export const ImgMap = styled.img`
  display: block;
  margin-bottom: 50px;

  @media (max-width: 500px) {
    margin-bottom: 20px;
    width: 100%;
  }
`;

export const TitleMap = styled.a`
  color: #50d05d;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.72px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 12px;
`;

export const SectionContact = styled.section`
  ${({ theme: { space } }) => css`
    /* max-width: ${space.large}; */
    width: 100%;
    margin: 0 auto;
    padding: 40px 0;
    background-color: #111;

    border-top: 1px solid #50d05d;
  `}
`;

export const ContactContent = styled.div`
  ${({ theme: { space } }) => css`
    max-width: ${space.medio};
    width: 100%;
    margin: 0 auto;

    h2 {
      color: #50d05d;
      text-align: center;
      font-family: "Play";
      font-size: 72px;
      font-style: normal;
      font-weight: 400;
      line-height: 80px;
      letter-spacing: 2.16px;
      margin-bottom: 12px;
    }

    p {
      color: #f7f7f7;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0.6px;

      span {
        color: #50d05d;
        font-weight: 700;
      }
    }

    @media (max-width: 500px) {
      h2 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    }
  `}
`;

export const GreenPlace = styled.div`
  ${({ theme: { space } }) => css`
    /* max-width: ${space.large}; */
    width: 100%;
    margin: 0 auto;
    padding: 50px 0;
    width: 100%;
    background-color: #50d05d;
  `}
`;

export const GreenPlaceContent = styled.div`
  ${({ theme: { space } }) => css`
    max-width: ${space.medio};
    width: 100%;
    margin: 0 auto;

    div + div {
      margin-top: 48px;
    }

    @media (max-width: 500px) {
      div + div {
        margin-top: 24px;
      }
    }
  `}
`;

export const City = styled.h4`
  color: #111;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0.96px;
  margin-bottom: 12px;

  @media (max-width: 500px) {
    font-size: 16px;
    margin-bottom: 0;
    text-align: center;
    line-height: 24px;
  }
`;

export const Phone = styled.h5`
  color: #eee;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;

  display: flex;
  justify-content: center;
  gap: 0 10px;
  align-items: center;

  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const ServiceGrid = styled.div`
  display: grid;
  padding: 50px 0;
  gap: 70px 50px;
  place-items: center;
  grid-template-areas:
    "table1 table2"
    "button button"
    "text text";

  > :nth-child(1) {
    grid-area: table1;
  }

  > :nth-child(2) {
    grid-area: button;
  }

  > :nth-child(3) {
    grid-area: table2;
  }

  > :nth-child(4) {
    grid-area: text;

    > :nth-child(2) {
      max-width: 778px;
      margin: 0 auto;
    }
  }

  @media (max-width: 500px) {
    grid-template-areas:
      "table1"
      "table2"
      "text"
      "button";

    gap: 32px 0;

    > :nth-child(3) {
      margin-top: 0;
    }

    > :nth-child(4) {
      margin-top: 0;

      > :nth-child(1) {
        max-width: 320px;
      }

      > :nth-child(2) {
        max-width: 320px;
      }
    }
  }
`;
