import React from "react";
import { LayoutTemplate } from "../LayoutTemplate";
import * as S from "./styles";
import { useMediaQuery } from "react-responsive";

export const OfflineTemplate = () => {
  const isMobile = useMediaQuery({ maxWidth: "500px" });

  return (
    <LayoutTemplate>
      <S.Container>
        <S.Logo src="/assets/imgs/log-logo.svg" alt="logo da empresa" />
        <S.Title>
          SISTEMA <span></span> EM ATUALIZAÇÃO
        </S.Title>
        <S.Text>
          Direcione-se até a <span>ECV</span> ou <span>CIRETRAN</span> mais
          próxima para realização do seu serviço.
        </S.Text>
        <S.SubTitle>Contatos</S.SubTitle>

        <S.WraperCardsFlex>
          <S.Card>
            <S.Localization>
              {" "}
              <img src="/assets/imgs/localizacao2.svg" alt="icone pin mapa" />
              SÃO LUÍS/ MA
            </S.Localization>
            <S.Contact>
              {" "}
              <img src="/assets/imgs/phone-green.svg" alt="icone telefone" />
              (98) 98494-3473
            </S.Contact>
          </S.Card>
        </S.WraperCardsFlex>

        {isMobile ? (
          <>
            <S.WraperCardsGrid>
              <S.Card>
                <S.Localization>
                  <img
                    src="/assets/imgs/localizacao2.svg"
                    alt="icone pin mapa"
                  />
                  TIMON / MA
                </S.Localization>
              </S.Card>

              <S.Card>
                <S.Localization>AÇAILANDIA/ MA</S.Localization>
              </S.Card>
            </S.WraperCardsGrid>

            <S.WraperCardsGrid>
              <S.Card>
                <S.Localization>CAROLINA / MA</S.Localization>
              </S.Card>

              <S.Card>
                <S.Localization>PRES. DUTRA / MA</S.Localization>
              </S.Card>
            </S.WraperCardsGrid>
          </>
        ) : (
          <>
            {" "}
            ({" "}
            <S.WraperCardsGrid>
              <S.Card>
                <S.Localization>
                  <img
                    src="/assets/imgs/localizacao2.svg"
                    alt="icone pin mapa"
                  />
                  TIMON / MA
                </S.Localization>
              </S.Card>

              <S.Card>
                <S.Localization>
                  <img
                    src="/assets/imgs/localizacao2.svg"
                    alt="icone pin mapa"
                  />
                  AÇAILANDIA/ MA
                </S.Localization>
              </S.Card>
            </S.WraperCardsGrid>
            <S.WraperCardsGrid>
              <S.Card>
                <S.Localization>
                  <img
                    src="/assets/imgs/localizacao2.svg"
                    alt="icone pin mapa"
                  />
                  CAROLINA / MA
                </S.Localization>
              </S.Card>

              <S.Card>
                <S.Localization>
                  <img
                    src="/assets/imgs/localizacao2.svg"
                    alt="icone pin mapa"
                  />
                  PRES. DUTRA / MA
                </S.Localization>
              </S.Card>
            </S.WraperCardsGrid>{" "}
          </>
        )}
        <S.Contact>
          <img src="/assets/imgs/phone-green.svg" alt="icone telefone" />
          (99) 99233-0731
        </S.Contact>
      </S.Container>
    </LayoutTemplate>
  );
};
